/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lined-textarea"},[(!_vm.disabled && _vm.hasLines)?_c('div',{staticClass:"lined-textarea__lines",style:({ 'padding-right': _vm.longestWidth + 'px' })},[_c('div',{ref:"lines",staticClass:"lined-textarea__lines__inner"},_vm._l((_vm.lines),function(line,index){return _c('p',{key:index,staticClass:"lined-textarea__lines__line",class:{
                    'lined-textarea__lines__line--invalid':
                        _vm.invalidLines.includes(line),
                },domProps:{"innerHTML":_vm._s(line)}})}),0)]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],ref:"textarea",staticClass:"lined-textarea__content",class:{
            'lined-textarea__content--disabled': _vm.disabled,
            'lined-textarea__content--wrap': !_vm.nowrap,
            'lined-textarea__content--nowrap': _vm.nowrap,
            'lined-textarea__content--no-lines': !_vm.hasLines,
        },style:(_vm.styles),attrs:{"readonly":_vm.readonly,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"cols":_vm.cols},domProps:{"value":(_vm.content)},on:{"scroll":_vm.scrollLines,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},_vm.onInput],"mousedown":_vm.detectResize,"keyup":_vm.limit}}),(!_vm.disabled && _vm.clearLines)?_c('div',{staticClass:"lined-textarea__clears"},[_c('div',{ref:"clears",staticClass:"lined-textarea__clears__inner"},_vm._l((_vm.lines),function(item,index){return _c('img',{key:index,staticClass:"clean-btn",attrs:{"src":require('@/assets/images/icons/trash.svg'),"alt":"Clean"},on:{"click":function($event){return _vm.onClearLine(item)}}})}),0)]):_vm._e(),_c('div',{ref:"helper",staticClass:"count-helper"})])}
var staticRenderFns = []

export { render, staticRenderFns }