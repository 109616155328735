//
//
//
//
//

import Tagify from "./tagify.js";
export default {
  name: "tagify",
  props: {
    mode: String,
    settings: Object,
    value: String
  },
  mounted() {
    this.tagigy = new Tagify(this.$el, this.settings);
  }
};