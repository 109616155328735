//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      text: ""
    };
  },
  mounted() {},
  methods: {
    async onOpen({
      text
    } = {}) {
      this.text = text.replace("\n", "<br/>");
    },
    submit({
      close
    }) {
      close();
      this.$emit("complete");
    }
  },
  computed: {
    actions() {
      return [{
        name: "submit",
        text: "ОК",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};